import React from 'react'
import { BsWhatsapp } from 'react-icons/bs'

import '../style/floatingIcons.css'
import Chatbot from './ChatBot/Chatbot'

const FloatingIcons = () => {
  return (
    <div className="floating-icons">
      {/* <span>
        <a href="https://wa.me/2347032205576?text=" target="_blank" rel="noreferrer">
          <BsWhatsapp size={40} />
        </a>
      </span> */}
      {/* <span>
        <Chatbot />
      </span> */}
      {/* <span>
        <a href="https://ng-eagle-booking.onrender.com" target="_blank" rel="noreferrer">
          <HiOutlineUserGroup size={40} />
        </a>
      </span> */}
    </div>
  )
}

export default FloatingIcons
