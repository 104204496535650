const passangersDatas = [
  {
    id: 1,
    type: 'Adult',
    quantity: 1,
  },
  {
    id: 2,
    type: 'Child',
    quantity: 0,
  },
  {
    id: 3,
    type: 'Infant',
    quantity: 0,
  },
]

export default passangersDatas
