export const staticPages = {
  chartered_sales: {
    backgroundImg: 'bg-[url("/src/images/manonplane.svg")] ',
    primaryImg: require("../images/manwithvisa.png"),
    primaryContent: (
      <div className="flex flex-col gap-[15px] lg:gap-[25px] text-[11px] lg:text-sm font-normal">
        <span>
          Since inception in 1959, Aero has been providing reliable and
          convenient air transport services.{" "}
        </span>
        <span>
          Aero’s personalised air services provide flight(s) at your own
          schedule and comfort to any destination.
        </span>
        <span>
          As the first domestic airline, we are fully ready to help achieve your
          mission with our excellent customer service from booking to boarding.{" "}
        </span>
        <span>
          Experience convenience, comfort and a welcoming service on our charter
          flights Fly on your own schedule and from any terminal that
          accommodates your aircraft choice.
        </span>
        <span>
          Tailor your itinerary by choosing the schedule, origin, and
          destinations that suits your needs Let your group have lighthearted
          pleasure in the air
        </span>
        <span>
          Lock in an all-inclusive price based on itinerary, fuel, and
          pre-chosen menus. Have your taste buds experience culinary selections
          with beverage choices and alcoholic beverages for an extra cost.{" "}
        </span>
        <span>
          Enjoy remote check-in, the availability of private TSA screening
          (where available) and other simplified travel options.{" "}
        </span>
        <span>
          Customer service agents are assigned to charter flights and to work on
          manifests.
        </span>
      </div>
    ),
    primaryImgPosition: "left",
    title: "Charter Services",
  },
  group_booking: {
    backgroundImg: 'bg-[url("/src/images/peoplewithphones.svg")] ',
    primaryImg: require("../images/manwithvisa.png"),
    primaryContent: (
      <div className="flex flex-col gap-[15px] lg:gap-[25px] text-[11px] lg:text-sm font-normal">
        <span>
          Since inception in 1959, Aero has been providing reliable and
          convenient air transport services.{" "}
        </span>
        <span>
          Aero’s personalised air services provide flight(s) at your own
          schedule and comfort to any destination.
        </span>
        <span>
          As the first domestic airline, we are fully ready to help achieve your
          mission with our excellent customer service from booking to boarding.{" "}
        </span>
        <span>
          Experience convenience, comfort and a welcoming service on our charter
          flights Fly on your own schedule and from any terminal that
          accommodates your aircraft choice.
        </span>
        <span>
          Tailor your itinerary by choosing the schedule, origin, and
          destinations that suits your needs Let your group have lighthearted
          pleasure in the air
        </span>
        <span>
          Lock in an all-inclusive price based on itinerary, fuel, and
          pre-chosen menus. Have your taste buds experience culinary selections
          with beverage choices and alcoholic beverages for an extra cost.{" "}
        </span>
        <span>
          Enjoy remote check-in, the availability of private TSA screening
          (where available) and other simplified travel options.{" "}
        </span>
        <span>
          Customer service agents are assigned to charter flights and to work on
          manifests.
        </span>
      </div>
    ),
    primaryImgPosition: "left",
    title: "Group Booking",
  },
  cargo_sales: {
    backgroundImg: 'bg-[url("/src/images/aeroplane.svg")] ',
    primaryImg: require("../images/cargoplane.png"),
    primaryContent: (
      <div className="flex flex-col gap-[15px] lg:gap-[25px] text-[11px] lg:text-sm font-normal font-sans">
        <div>
          <h2 className="font-bold text-[16px] lg:text-[22px]">
            Network and Stations
          </h2>
          <span>
            We offer Cargo Services through accredited Freight Forwarders and
            from all the airports we operate with Lagos and Abuja as our main
            hubs. Our services currently cover nine stations including; Benin,
            Asaba, Port Harcourt, Calabar, Kano, Bauchi, Yola, Sokoto and
            Maiduguri. As our routes continue to evolve, you can rest assured we
            will provide seamless service to get your cargo to its destination.{" "}
          </span>
        </div>
        <div className="flex flex-col gap-[15px] lg:gap-[25px]">
          <h2 className="font-bold text-[16px] lg:text-[22px]">
            CARGO HANDLING PROCEDURES
          </h2>
          <span>
            Please note, shipment dimensions might be streamlined based on
            specific loading procedures.{" "}
          </span>
          <span>
            We expect all packaged goods/units to conform with prescribed
            dimensions and weights as per our aircraft type;
          </span>
          <span>B737 = 30 inches by 44 inches @ 50kg per piece</span>
          <span>Dash-8 = 45 inches by 55 inches @ 30kg per</span>
          <span>
            WHY CHOOSE AERO CARGO Professionalism Reliability Care Affordability
          </span>
          <span>
            WHY CHOOSE AERO CARGO Professionalism Reliability Care Affordability
          </span>
          <span>
            To learn more about moving your cargo with us, please contact any of
            our dedicated staff via the e-mail address below to answer your
            questions about booking your shipments, billing inquiries and
            more <a className="underline cursor-pointer hover:text-[#69ab4a] hover:underline">aero.cargoservices@ACN.Aero</a>
          </span>
        </div>
      </div>
    ),
    primaryImgPosition: "right",
    title: "Cargo Sales",
  },
};
