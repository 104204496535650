import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, useLocation } from "react-router-dom";
import "./style/header.css";
import About from "./pages/About";
import LuggagePolicy from "./pages/LuggagePolicy";
import TravelExtra from "./pages/TravelExtra";
import Cafe from "./pages/Cafe";
import TaxiRental from "./pages/TaxiRental";
import CafeDetails from "./pages/CafeDetails";
import DealAndOffer from "./pages/DealAndOffer";
import SpecialAssistance from "./pages/SpecialAssistance";
import Privacy from "./component/Privacy";
import Terms from "./component/Terms";
import TAndCs from './pages/Terms'
import Tourist from "./component/Turist/Tourist";
import Flips from "./pages/Flips";
import GoogleTranslate from "./component/googleTranslate";
import NotFound from "./pages/NotFound";
import Home from "./component/homePage/Home";
import SpecialAssistanceForm from "./pages/SpecialAssistanceForm";
import ContactUs from "./pages/ContactUs";
import News from "./pages/News";
import Footer from "./Layout/Footer";
import NewsDetails from "./pages/NewsDetails";
import Header from "./Layout/Header";
import Announcements from "./component/Announcements";
import Career from "./career/pages";
import CareerHome from "./career/pages/home";
import Applications from "./career/pages/Applications";
import Saved from "./career/pages/Saved";
import Profile from "./career/pages/Profile";
import AllJobs from "./career/pages/AllJobs";
import LoginContainer from "./career/pages/LoginContainer";
import LoginOtp from "./career/pages/LoginOtp";
import RegContainer from "./career/pages/RegContainer";
import JobDetails from "./career/pages/JobDetails";
import HotelRental from "./pages/HotelRental";
import FloatingIcons from "./component/FloatingIcons";
import PassengersProvider from "./context/FlightBookingContext";
import AboutUs from "./pages/AboutUs";
import UtilPagesWrapper from "./pages/UtilPagesWrapper";
import { staticPages } from "./utilities/utils";
import CorporateSales from './pages/CorporateSales'
import TravelAgentSales from './pages/TravelAgentSales'
import ContactUsPage from './pages/ContactUsPage'
import Loader from './component/Loaders/Loader'

function App() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can use "auto" for instant scrolling
    });
  }, [location.pathname]);

  useEffect(() => {
    // Simulate an API call
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);

  return (
    <PassengersProvider>
      { isLoading ? (
          <Loader />
        ) : null
      }
      <GoogleTranslate />
      <Announcements />
      <Header />
      {/* <Flips /> */}
      <main>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="about_us" element={<AboutUs />} />
          <Route
            path="charter_sales"
            element={
              <UtilPagesWrapper
              primaryContent={staticPages.chartered_sales.primaryContent}
                backgroundImg={staticPages.chartered_sales.backgroundImg}
                primaryImg={staticPages.chartered_sales.primaryImg}
                primaryImgPosition={staticPages.chartered_sales.primaryImgPosition}
                title={staticPages.chartered_sales.title}
                path="charter_sales"
              />
            }
          />
          <Route
            path="group_booking"
            element={
              <UtilPagesWrapper
              primaryContent={staticPages.group_booking.primaryContent}
                backgroundImg={staticPages.group_booking.backgroundImg}
                primaryImg={staticPages.group_booking.primaryImg}
                primaryImgPosition={staticPages.group_booking.primaryImgPosition}
                title={staticPages.group_booking.title}
                path="group_booking"
              />
            }
          />
          <Route
            path="travel_agent_sale"
            element={
              <UtilPagesWrapper
              primaryContent={staticPages.group_booking.primaryContent}
                backgroundImg={staticPages.group_booking.backgroundImg}
                primaryImg={staticPages.group_booking.primaryImg}
                primaryImgPosition={staticPages.group_booking.primaryImgPosition}
                title={staticPages.group_booking.title}
                path="travel_agent_sale"
              />
            }
          />
          <Route
            path="cargo_sales"
            element={
              <UtilPagesWrapper
              primaryContent={staticPages.cargo_sales.primaryContent}
                backgroundImg={staticPages.cargo_sales.backgroundImg}
                primaryImg={staticPages.cargo_sales.primaryImg}
                primaryImgPosition={staticPages.cargo_sales.primaryImgPosition}
                title={staticPages.cargo_sales.title}
                path="cargo_sales"
              />
            }
          />
          <Route
            path="corporate_sales"
            element={
              <UtilPagesWrapper
              primaryContent={staticPages.cargo_sales.primaryContent}
                backgroundImg={staticPages.cargo_sales.backgroundImg}
                primaryImg={staticPages.cargo_sales.primaryImg}
                primaryImgPosition={staticPages.cargo_sales.primaryImgPosition}
                title={staticPages.cargo_sales.title}
                path="corporate_sales"
              />
            }
          />
          <Route path="baggage_policy" element={<LuggagePolicy />} />
          <Route path="travel_extras" element={<TravelExtra />} />
          <Route path="cafe" element={<Cafe />} />
          <Route path="car_rental" element={<TaxiRental />} />
          <Route path="cafe_details/:id" element={<CafeDetails />} />
          <Route path="deals_offer" element={<DealAndOffer />} />
          <Route path="deals_offer/:id" element={<DealAndOffer />} />
          <Route path="special_assistance" element={<SpecialAssistance />} />
          <Route
            path="special_assistance_form"
            element={<SpecialAssistanceForm />}
          />
          <Route path="privacy_policy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="tourist_attraction" element={<Tourist />} />
          <Route path="*" element={<NotFound />} />
          <Route path="contact_us" element={<ContactUs />} />
          <Route path="news" element={<News />} />
          {/* <Route path="corporate_sales" element={<CorporateSales />} /> */}
          {/* <Route path="travel_agent_sale" element={<TravelAgentSales />} /> */}
          <Route path="contact_page" element={<ContactUsPage />} />
          <Route path="terms_and_condition" element={<TAndCs />} />
          <Route path="news_details/:id" element={<NewsDetails />} />
          <Route path="hotel_rental" element={<HotelRental />} />
          <Route path="career" element={<Career />}>
            <Route index element={<CareerHome />} />
            <Route path="jobs" element={<AllJobs />} />
            <Route path="jobs/:id" element={<JobDetails />} />
            <Route path="search/:query" element={<AllJobs />} />
            <Route path="applications" element={<Applications />} />
            <Route path="saved" element={<Saved />} />
            <Route path="profile" element={<Profile />} />
            <Route path="login" element={<LoginContainer />} />
            <Route path="login_otp" element={<LoginOtp />} />
            <Route path="verify/:token" element={<LoginContainer />} />
            <Route path="verify" element={<RegContainer />} />
            <Route path="register" element={<RegContainer />} />
          </Route>
        </Routes>
      </main>
      <FloatingIcons />
      <Footer />
    </PassengersProvider>
  );
}

export default App;
