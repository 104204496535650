export const TuristJson= [
//     {
// title: 'title',
// subhead: 'subhead',
// body: 'body',
// image: require('../../images/1000w_q95 1.png'),
//     },
   
//     {
// title: 'title',
// subhead: 'subhead',
// body: 'subhead',
// image: require('../../images/turist attract1.png'),
//     },
   

]